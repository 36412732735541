.meField {
  display: flex;
  margin-left: 20px;
  padding-bottom: 20px;
}

.meCheckbox {
  appearance: initial;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  border: 1px solid #797E7C;
  margin-right: 10px;
}

.meCheckbox:checked {
  background: #02DB96;
  border-color: #02DB96;
}

@media (max-width: 768px) {
  .meField {
    margin-left: 0;
  }
}

