.inputWrapper {
  position: relative;
  display: inline-block;
  width: 640px;
  height: auto;
  margin-bottom: 40px;
}

.label {
  color: #797E7C;
  font-size: 16px;
  line-height: 100%;
  text-align: left;
  display: block;
}

.input {
  width: 100%;
  height: 100%;
  font-size: 24px;
  line-height: 24px;
  border: none;
  border-bottom: 1px solid #797E7C;
  color: #001A12;
  padding-top: 10px;
  padding-bottom: 5px;
}

.input:focus {
  border-bottom: 2px solid #02DB96;
  outline: none;
}

input.input:-internal-autofill-selected {
  background-color: white !important;
}

.error {
  position: absolute;
  bottom: -20px;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: #02DB96;
}
