.widgetEditor {
  display: flex;
  flex-direction: column;
}

.widgetEditor header {
  margin-bottom: 40px;
}

.widgetEditor .description {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 30px;
  line-height: 150%;
}

.widgetEditor .widget {
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.widgetEditor textarea {
  width: 100%;
  height: 200px;
  padding: 20px;
  background: #F8F9FA;
  border: 1px solid #D5DAE2;
  border-radius: 4px;
  display: block;
  border-top-left-radius: 0;
  font-size: 0.8em;
}

.widgetEditor .codeContainer {
  position: relative;
  margin-top: 40px;
}

.widgetEditor .codeContainer button {
  margin-top: 20px;
}

.widgetEditor .codeContainer button img {
  vertical-align: text-top;
}

.widgetEditor .colorPickerCont {
  position: relative;
}

.widgetEditor .colorPickerCont .picker {
  position: absolute !important;
  z-index: 10;
  left: calc((100% - 276px) / 2);
  top: 60px;
}

.transferCont {
  margin-top: 60px;
}
.transferCont p {
  margin-bottom: 60px;
  font-weight: bold;
}

.transfer {
  display: flex;
  align-items: flex-start;
  max-width: 600px;
  margin: auto;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

.transfer button {
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}

.backLink {
  display: flex;
  justify-content: center;
  color: #02DB96;
}

.thankyou {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

.thankyou img {
  height: 64px;
  object-fit: contain;
  margin-bottom: 20px;
}

.thankyou p {
  font-weight: bold;
}
