.navigation_navigation__3kror {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 96px;
  background-color: white;
  padding: 20px 60px;
  text-align: center;
  z-index: 20;
}

.navigation_navigation__3kror .navigation_left__2sFA6, .navigation_navigation__3kror .navigation_right__2wEa5 {
  display: flex;
  justify-content: flex-start;
}

.navigation_navigation__3kror .navigation_link__26eOL {
  margin-left: 30px;
  cursor: pointer;
  list-style-type: none;
  text-decoration: none;
}
.navigation_navigation__3kror .navigation_link__26eOL a {
  text-decoration: none;
  color: black;
}

.navigation_navigation__3kror .navigation_logo__3-7lG {
  width: 120px;
  height: 35px;
  cursor: pointer;
}

.Home_home__3tmoj {
  background-image: url(/static/media/home-bg.dbc4e825.svg);
  background-attachment: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 50%;
  min-height: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
}
.Home_home__3tmoj header {
  max-width: 70%;
  margin: auto;
}
.Home_home__3tmoj header h1{
  margin-top: 0;
}

.Home_home__3tmoj header p,
.Home_home__3tmoj dl,
.Home_home__3tmoj a {
  color: #797E7C;
}

.Home_home__3tmoj a {
  font-weight: bold;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

.Home_home__3tmoj dl {
  text-align: left;
  max-width: 500px;
  margin: 60px auto;
  line-height: 1.4em;
}

.Home_home__3tmoj button {
  margin-top: 40px;
}


.Home_home__3tmoj dl dt {
  color: black;
  font-weight: bold;
  margin-top: 2em;
}

.Home_home__3tmoj dl dd {
  margin-left: 0;
  margin-top: 0.5em;
}

.Home_fundraiser__2MP-U {
  background-color: #F5F6F7;
  text-align: left;
  max-width: 400px;
  margin: 10px 10px 40px 10px;
  box-shadow: 4px 4px 16px -4px rgba(0,0,0,0.15);
  padding: 16px;
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button_button__MPBy1 {
  width: auto;
  height: 44px;
  color: white;
  background: #02DB96;
  border-radius: 40px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;
  padding: 0 40px;
  display: inline-block;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.15);
}
.button_button__MPBy1.button_naked__2ohiy {
  height: auto;
  color: black;
  border-radius: 0;
  line-height: 100%;
  background: none;
  border-bottom: 1px solid black;
  font-size: 12px;
  padding: 15px 0 5px;
}

.button_button__MPBy1.button_copy__2zSYw {
  background-color: #BCBCBC;
}
.button_button__MPBy1.button_copy__2zSYw:active svg g {
  fill: black;
}
.button_button__MPBy1.button_copy__2zSYw.button_small__18l_4 {
  padding: 0;
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
}

.button_button__MPBy1.button_colorBtn__2H12p {
  display: block;
  width: 100%;
  border-radius: 0;
  color: #797E7C;
  text-align: left;
  font-size: 24px;
  font-weight: normal;
  padding: 7px;
  background-color: rgba(0, 0, 0, 0);
  margin: 10px 0;
  text-shadow: none;
}
.button_button__MPBy1.button_colorBtn__2H12p.button_close__1mFKG {
  background: url(/static/media/close-button.88645411.svg) no-repeat calc(100% - 16px) white;
  background-size: 14px 14px;
}

.button_button__MPBy1.button_margin__qBDtL {
  margin: 15px;
}

.button_button__MPBy1:hover {

}

.button_button__MPBy1:active {

}

.button_button__MPBy1:disabled {
  opacity: 0.5;
  cursor: default;
}

a.button_button__MPBy1 {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 240px;
  text-decoration: none;
  margin: 10px;
}

.iframeContainer_iframeContainer__3FGuN {
  position: relative;
  display: flex;
  justify-content: center;
  width: 320px;
  height: 480px;
}

.iframeContainer_iframeContainer__3FGuN .iframeContainer_spinner__1PGBV {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}

.iframeContainer_iframeContainer__3FGuN iframe {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #D5DAE2;
  height: 480px;
  position: relative;
}

.iframeContainer_iframeContainer__3FGuN iframe.iframeContainer_loading__3JjXI {
  visibility: hidden;
}

.iframeContainer_iframeContainer__3FGuN.iframeContainer_expired__1ExIJ {
  height: auto;
}

.iframeContainer_iframeContainer__3FGuN.iframeContainer_expired__1ExIJ iframe{
  height: 300px;
}

.loaderComp_loader__Bsq4E {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
  align-items: center;

}

.loaderComp_loaderIcon__y-AhW {
  width: 64px;
  height: 64px;
  margin-top: 40px;
}

.loaderComp_loaderIcon__y-AhW img {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation: loaderComp_rotating__3vqOg 2s linear infinite;
          animation: loaderComp_rotating__3vqOg 2s linear infinite;
}

@-webkit-keyframes loaderComp_rotating__3vqOg {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loaderComp_rotating__3vqOg {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.NewCampaign_newCampaign__27yau {
  text-align: left;
  padding: 50px 15%;
}

@media (max-width: 768px) {
  .NewCampaign_newCampaign__27yau {
    padding: 50px 10px;
  }
}

.widgetEditor_widgetEditor__34qeF {
  display: flex;
  flex-direction: column;
}

.widgetEditor_widgetEditor__34qeF header {
  margin-bottom: 40px;
}

.widgetEditor_widgetEditor__34qeF .widgetEditor_description__1B7my {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 30px;
  line-height: 150%;
}

.widgetEditor_widgetEditor__34qeF .widgetEditor_widget__2neun {
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.widgetEditor_widgetEditor__34qeF textarea {
  width: 100%;
  height: 200px;
  padding: 20px;
  background: #F8F9FA;
  border: 1px solid #D5DAE2;
  border-radius: 4px;
  display: block;
  border-top-left-radius: 0;
  font-size: 0.8em;
}

.widgetEditor_widgetEditor__34qeF .widgetEditor_codeContainer__1Sedh {
  position: relative;
  margin-top: 40px;
}

.widgetEditor_widgetEditor__34qeF .widgetEditor_codeContainer__1Sedh button {
  margin-top: 20px;
}

.widgetEditor_widgetEditor__34qeF .widgetEditor_codeContainer__1Sedh button img {
  vertical-align: text-top;
}

.widgetEditor_widgetEditor__34qeF .widgetEditor_colorPickerCont__1osJ2 {
  position: relative;
}

.widgetEditor_widgetEditor__34qeF .widgetEditor_colorPickerCont__1osJ2 .widgetEditor_picker__tT33G {
  position: absolute !important;
  z-index: 10;
  left: calc((100% - 276px) / 2);
  top: 60px;
}

.widgetEditor_transferCont__3fd7_ {
  margin-top: 60px;
}
.widgetEditor_transferCont__3fd7_ p {
  margin-bottom: 60px;
  font-weight: bold;
}

.widgetEditor_transfer__14YHP {
  display: flex;
  align-items: flex-start;
  max-width: 600px;
  margin: auto;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

.widgetEditor_transfer__14YHP button {
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}

.widgetEditor_backLink__3w1rD {
  display: flex;
  justify-content: center;
  color: #02DB96;
}

.widgetEditor_thankyou__3cMW6 {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

.widgetEditor_thankyou__3cMW6 img {
  height: 64px;
  object-fit: contain;
  margin-bottom: 20px;
}

.widgetEditor_thankyou__3cMW6 p {
  font-weight: bold;
}

.select_selectWrapper__4VRjq {
  width: 100%;
  padding: 10px 0;
}

.select_select__1qOqL {
  padding: 7px;
  border: none;
  border-bottom: 1px solid #BCBCBC;
  color: #797E7C;
  border-radius: 0;
  background: white;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 24px;
  background: url(/static/media/down-arrow.ede7daf0.svg) no-repeat calc(100% - 16px) white;
  background-size: 16px 16px;
}
.select_select__1qOqL option {
  color: #797E7C;
}
.select_select__1qOqL:placeholder {
  color: #BCBCBC;
}
.select_select__1qOqL:focus {
  outline: none;
  border-bottom: 2px solid #02DB96;
}

.select_select-css__39We0::-ms-expand {
    display: none;
}

.createContractForm_createContractForm__2u_R1 form {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.createContractForm_datepicker__ZU2MX {
  width: 100%;
}

.createContractForm_field__2RCPs {
  width: 100%;
  display: flex;
  align-items: center;
}

.createContractForm_buttonRow__Z9WAV button {
  position: relative;
}


@media (max-width: 768px) {
  .createContractForm_createContractForm__2u_R1 form {
    margin-top: 60px;
  }

  .createContractForm_field__2RCPs {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .createContractForm_field__2RCPs > div {
    width: 100%;
    margin-bottom: 25px;
  }

  .createContractForm_buttonRow__Z9WAV button {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .createContractForm_datepicker__ZU2MX > div > div > div {
    width: 100%;
  }
}
.input_inputWrapper__1w979 {
  position: relative;
  display: inline-block;
  width: 640px;
  height: auto;
  margin-bottom: 40px;
}

.input_label__1IhEx {
  color: #797E7C;
  font-size: 16px;
  line-height: 100%;
  text-align: left;
  display: block;
}

.input_input__1Lyyr {
  width: 100%;
  height: 100%;
  font-size: 24px;
  line-height: 24px;
  border: none;
  border-bottom: 1px solid #797E7C;
  color: #001A12;
  padding-top: 10px;
  padding-bottom: 5px;
}

.input_input__1Lyyr:focus {
  border-bottom: 2px solid #02DB96;
  outline: none;
}

input.input_input__1Lyyr:-internal-autofill-selected {
  background-color: white !important;
}

.input_error__2ZUcA {
  position: absolute;
  bottom: -20px;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: #02DB96;
}

.meCheckbox_meField__180Pf {
  display: flex;
  margin-left: 20px;
  padding-bottom: 20px;
}

.meCheckbox_meCheckbox__1CUF1 {
  -webkit-appearance: initial;
     -moz-appearance: initial;
          appearance: initial;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  border: 1px solid #797E7C;
  margin-right: 10px;
}

.meCheckbox_meCheckbox__1CUF1:checked {
  background: #02DB96;
  border-color: #02DB96;
}

@media (max-width: 768px) {
  .meCheckbox_meField__180Pf {
    margin-left: 0;
  }
}


.Campaign_centerRow__3sPZL {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.Campaign_centerRow__3sPZL button {
  margin-top: 40px;
}

.Campaign_centerColumn__38Fn8 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.Campaign_padding__1hOGG {
  padding: 20px 0;
}

.Campaign_centerColumn__38Fn8.Campaign_padding__1hOGG img {
  margin-bottom: 20px;
}

.Campaign_halfColumn__2yQXR {
  width: 50%;
  padding: 5px 20px;
  box-sizing: border-box;
}

.Campaign_halfColumn__2yQXR:first-of-type {
  border-right: 1px solid #BCBCBC;
}

.Campaign_halfColumn__2yQXR:only-child {
  border: none;
}

.Campaign_halfColumn__2yQXR p {
  width: 80%;
  margin: 0 auto;
}

.Campaign_button__2nBBn {
  text-align: center;
}

.Campaign_disclaimer__2xYUS {
  color: #02DB96;
  font-size: 18px;
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Inter var', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  color: #001A12;
  padding:100px 20px 0px 20px;
}

html, body, .App, #root {
  width: 100%;
  min-height: 100%;
  height: auto;
}
html, body, #root, .App, .App * {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

h1.title,
h1.subtitle,
h2.subtitle {
  font-family: big-caslon-fb, serif;
  font-weight: 400;
  font-style: normal;
}

h1.title {
  font-size: 62px;
}

h1.subtitle {
  font-size: 46px;
}

h2.subtitle {
  font-size: 32px;
}

h1, h2, h3, h4, h5, h6, p, a {
  font-family: 'Inter var', sans-serif;
  text-align: center;
}

h1 {
  font-size: 36px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 20px;
}
p {
  font-size: 14px;
  line-height: 150%;
}
p.big {
  font-size: 20px;
}
p.strong {
  color: black;
}

.strong {
  font-weight: 700;
}

.addressCont {
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: 'Inter var', sans-serif;
  font-size: 12px;
  color: #797E7C;
}

section {
  max-width: 780px;
  width: calc(100% - 40px);
  margin: 200px auto;
}

.home section h1 {
  position: relative;
}

.home section h1:before {
  content: "";
  width: 50px;
  height: 25px;
  position: absolute;
  background-image: url(/static/media/bird-01.20394e33.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  left: calc(50% - 25px);
  top: -40px;
}

section.benefits {
  margin-top: 260px;
}

section.chari p {
  text-align: left;
}
section.chari p.strong {
  margin: 20px 0;
}

section.chari > div:first-of-type {
  margin-bottom: 40px;
}


section.social-proof p:last-child {
  max-width: 600px;
  margin: 40px auto;
}

section .subtitle {
  margin-bottom: 60px;
}

.divider {
  width: 25px;
  height: 25px;
  background-image: url(/static/media/coin-divider.9a0694ab.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 60px auto;
}

ul {
  display: inline-block;
  text-align: center;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

li {
  color: #797E7C;
  font-size: 20px;
  line-height: 150%;
  list-style-type: none;
}

.logo {
  margin: 0 auto;
}
.logo.hospischool-logo {
  margin-top: 60px;
  width: 80px;
  height: 80px;
}
.logo.gitcoin-logo {
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
}

.quote p {
  font-style: italic;
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.quote span {
  font-size: 14px;
  display: block;
  text-align: right;
  margin-right: 20px;
}
.quote a, .social-proof a {
  color: #02DB96;
  font-weight: normal;
  background-color: white;
  text-decoration: none;
}
.quote span {
  margin-top: 40px;
  margin-bottom: 5px;
  font-weight: 600;
}

.featured {
  max-width: 860px;
}

.featured > div:first-of-type {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

