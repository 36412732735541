.home {
  background-image: url('./../assets/home-bg.svg');
  background-attachment: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 50%;
  min-height: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
}
.home header {
  max-width: 70%;
  margin: auto;
}
.home header h1{
  margin-top: 0;
}

.home header p,
.home dl,
.home a {
  color: #797E7C;
}

.home a {
  font-weight: bold;
  text-decoration-skip-ink: none;
}

.home dl {
  text-align: left;
  max-width: 500px;
  margin: 60px auto;
  line-height: 1.4em;
}

.home button {
  margin-top: 40px;
}


.home dl dt {
  color: black;
  font-weight: bold;
  margin-top: 2em;
}

.home dl dd {
  margin-left: 0;
  margin-top: 0.5em;
}

.fundraiser {
  background-color: #F5F6F7;
  text-align: left;
  max-width: 400px;
  margin: 10px 10px 40px 10px;
  box-shadow: 4px 4px 16px -4px rgba(0,0,0,0.15);
  padding: 16px;
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
