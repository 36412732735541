body {
  margin: 0;
  font-family: 'Inter var', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  color: #001A12;
  padding:100px 20px 0px 20px;
}

html, body, .App, #root {
  width: 100%;
  min-height: 100%;
  height: auto;
}
html, body, #root, .App, .App * {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

h1.title,
h1.subtitle,
h2.subtitle {
  font-family: big-caslon-fb, serif;
  font-weight: 400;
  font-style: normal;
}

h1.title {
  font-size: 62px;
}

h1.subtitle {
  font-size: 46px;
}

h2.subtitle {
  font-size: 32px;
}

h1, h2, h3, h4, h5, h6, p, a {
  font-family: 'Inter var', sans-serif;
  text-align: center;
}

h1 {
  font-size: 36px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 20px;
}
p {
  font-size: 14px;
  line-height: 150%;
}
p.big {
  font-size: 20px;
}
p.strong {
  color: black;
}

.strong {
  font-weight: 700;
}

.addressCont {
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: 'Inter var', sans-serif;
  font-size: 12px;
  color: #797E7C;
}

section {
  max-width: 780px;
  width: calc(100% - 40px);
  margin: 200px auto;
}

.home section h1 {
  position: relative;
}

.home section h1:before {
  content: "";
  width: 50px;
  height: 25px;
  position: absolute;
  background-image: url('./assets/bird-01.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  left: calc(50% - 25px);
  top: -40px;
}

section.benefits {
  margin-top: 260px;
}

section.chari p {
  text-align: left;
}
section.chari p.strong {
  margin: 20px 0;
}

section.chari > div:first-of-type {
  margin-bottom: 40px;
}


section.social-proof p:last-child {
  max-width: 600px;
  margin: 40px auto;
}

section .subtitle {
  margin-bottom: 60px;
}

.divider {
  width: 25px;
  height: 25px;
  background-image: url('./assets/coin-divider.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 60px auto;
}

ul {
  display: inline-block;
  text-align: center;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

li {
  color: #797E7C;
  font-size: 20px;
  line-height: 150%;
  list-style-type: none;
}

.logo {
  margin: 0 auto;
}
.logo.hospischool-logo {
  margin-top: 60px;
  width: 80px;
  height: 80px;
}
.logo.gitcoin-logo {
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
}

.quote p {
  font-style: italic;
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.quote span {
  font-size: 14px;
  display: block;
  text-align: right;
  margin-right: 20px;
}
.quote a, .social-proof a {
  color: #02DB96;
  font-weight: normal;
  background-color: white;
  text-decoration: none;
}
.quote span {
  margin-top: 40px;
  margin-bottom: 5px;
  font-weight: 600;
}

.featured {
  max-width: 860px;
}

.featured > div:first-of-type {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
