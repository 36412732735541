.iframeContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 320px;
  height: 480px;
}

.iframeContainer .spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}

.iframeContainer iframe {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #D5DAE2;
  height: 480px;
  position: relative;
}

.iframeContainer iframe.loading {
  visibility: hidden;
}

.iframeContainer.expired {
  height: auto;
}

.iframeContainer.expired iframe{
  height: 300px;
}
