.navigation {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 96px;
  background-color: white;
  padding: 20px 60px;
  text-align: center;
  z-index: 20;
}

.navigation .left, .navigation .right {
  display: flex;
  justify-content: flex-start;
}

.navigation .link {
  margin-left: 30px;
  cursor: pointer;
  list-style-type: none;
  text-decoration: none;
}
.navigation .link a {
  text-decoration: none;
  color: black;
}

.navigation .logo {
  width: 120px;
  height: 35px;
  cursor: pointer;
}
