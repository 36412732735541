.centerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.centerRow button {
  margin-top: 40px;
}

.centerColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.padding {
  padding: 20px 0;
}

.centerColumn.padding img {
  margin-bottom: 20px;
}

.halfColumn {
  width: 50%;
  padding: 5px 20px;
  box-sizing: border-box;
}

.halfColumn:first-of-type {
  border-right: 1px solid #BCBCBC;
}

.halfColumn:only-child {
  border: none;
}

.halfColumn p {
  width: 80%;
  margin: 0 auto;
}

.button {
  text-align: center;
}

.disclaimer {
  color: #02DB96;
  font-size: 18px;
  font-weight: 700;
}
