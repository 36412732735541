.selectWrapper {
  width: 100%;
  padding: 10px 0;
}

.select {
  padding: 7px;
  border: none;
  border-bottom: 1px solid #BCBCBC;
  color: #797E7C;
  border-radius: 0;
  background: white;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 24px;
  background: url('./../assets/down-arrow.svg') no-repeat calc(100% - 16px) white;
  background-size: 16px 16px;
}
.select option {
  color: #797E7C;
}
.select:placeholder {
  color: #BCBCBC;
}
.select:focus {
  outline: none;
  border-bottom: 2px solid #02DB96;
}

.select-css::-ms-expand {
    display: none;
}
