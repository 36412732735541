.button {
  width: auto;
  height: 44px;
  color: white;
  background: #02DB96;
  border-radius: 40px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;
  padding: 0 40px;
  display: inline-block;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.15);
}
.button.naked {
  height: auto;
  color: black;
  border-radius: 0;
  line-height: 100%;
  background: none;
  border-bottom: 1px solid black;
  font-size: 12px;
  padding: 15px 0 5px;
}

.button.copy {
  background-color: #BCBCBC;
}
.button.copy:active svg g {
  fill: black;
}
.button.copy.small {
  padding: 0;
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
}

.button.colorBtn {
  display: block;
  width: 100%;
  border-radius: 0;
  color: #797E7C;
  text-align: left;
  font-size: 24px;
  font-weight: normal;
  padding: 7px;
  background-color: rgba(0, 0, 0, 0);
  margin: 10px 0;
  text-shadow: none;
}
.button.colorBtn.close {
  background: url('./../assets/close-button.svg') no-repeat calc(100% - 16px) white;
  background-size: 14px 14px;
}

.button.margin {
  margin: 15px;
}

.button:hover {

}

.button:active {

}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

a.button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 240px;
  text-decoration: none;
  margin: 10px;
}
