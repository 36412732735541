.createContractForm form {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.datepicker {
  width: 100%;
}

.field {
  width: 100%;
  display: flex;
  align-items: center;
}

.buttonRow button {
  position: relative;
}


@media (max-width: 768px) {
  .createContractForm form {
    margin-top: 60px;
  }

  .field {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .field > div {
    width: 100%;
    margin-bottom: 25px;
  }

  .buttonRow button {
    left: 50%;
    transform: translateX(-50%);
  }

  .datepicker > div > div > div {
    width: 100%;
  }
}